import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HelperService, OResponse } from '../../../service/service';
import { Address } from "ngx-google-places-autocomplete/objects/address";

@Component({
    selector: 'registerstore',
    templateUrl: './registerstore.component.html',
})
export class RegisterStoreComponent implements OnInit {
    Form_AddUser_Processing = false;
    Form_AddUser: FormGroup;
    constructor(
        public _FormBuilder: FormBuilder,
        public _TranslateService: TranslateService,
        public _Router: Router,
        public _HelperService: HelperService,
    ) {
        this.Form_AddUser = _FormBuilder.group({
            OperationType: 'new',
            Task: this._HelperService.AppConfig.Api.Core.Register,
            AccountTypeCode: this._HelperService.AppConfig.AccountType.Store,
            AccountOperationTypeCode: this._HelperService.AppConfig.AccountOperationType.Offline,
            RegistrationSourceCode: this._HelperService.AppConfig.RegistrationSource.System,
            OwnerKey: this._HelperService.UserAccount.AccountKey,
            SubOwnerKey: null,
            BankKey: null,
            // Password: [
            //     null,
            //     Validators.compose([
            //         Validators.required,
            //         Validators.minLength(8),
            //         Validators.maxLength(20),
            //         Validators.pattern(
            //             this._HelperService.AppConfig.ValidatorRegex.Password
            //         )
            //     ])
            // ],
            // DisplayName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(25)])],
            Name: [this._HelperService.UserAccount.DisplayName, Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(256)])],
            // FirstName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(128)])],
            // LastName: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(128)])],
            EmailAddress: [this._HelperService.User.EmailAddress, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
            MobileNumber: [this._HelperService.User.MobileNumber, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(14)])],
            // ContactNumber: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(18)])],
            // SecondaryEmailAddress: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(2)])],
            WebsiteUrl: [null, Validators.compose([Validators.minLength(2), Validators.pattern(this._HelperService.AppConfig.ValidatorRegex.WebsiteUrl)])],
            Description: null,
            Address: [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(256)])],
            CountValue: 0,
            AverageValue: 0,
            ApplicationStatusCode: null,
            EmailVerificationStatus: 0,
            EmailVerificationStatusDate: null,
            NumberVerificationStatus: 0,
            NumberVerificationStatusDate: null,
            CountryKey: null,
            RegionKey: null,
            RegionAreaKey: null,
            CityKey: null,
            CityAreaKey: null,
            BusinessCategories: null,
            AccountLevelCode: 'accountlevel.ca',
            SubscriptionKey: 'merchantbasic',
            AccountPercentage: 0,
            StatusCode: this._HelperService.AppConfig.Status.Inactive,
            Owners: [],
            Configuration: [],
        });
    }
    ngOnInit() {

    }
    Form_AddUser_Process(_FormValue: any) {
        if (this.Form_AddUser_Latitude == 0 || this.Form_AddUser_Longitude == 0) {
            this._HelperService.NotifyError('Mark your business location on map to continue');
        }
        else {
            _FormValue.DisplayName = _FormValue.Name;
            _FormValue.UserName = this._HelperService.GeneratePassoword();
            _FormValue.Password = this._HelperService.GeneratePassoword();
            this.Form_AddUser_Processing = true;
            let _OResponse: Observable<OResponse>;
            _OResponse = this._HelperService.PostData(this._HelperService.AppConfig.NetworkLocation.V2.System, _FormValue);
            _OResponse.subscribe(
                _Response => {
                    this.Form_AddUser_Processing = false;
                    if (_Response.Status == this._HelperService.StatusSuccess) {
                        window.location.href = this._HelperService.AppConfig.Pages.System.MerchantRewardSetup;
                    }
                    else {
                        this._HelperService.NotifyError(_Response.Message);
                    }
                },
                _Error => {
                    this.Form_AddUser_Processing = false;
                    this._HelperService.HandleException(_Error);
                });
        }
    }

    Form_AddUser_Address: string = null;
    Form_AddUser_Latitude: number = 0;
    Form_AddUser_Longitude: number = 0;
    Form_AddUser_PlaceMarkerClick(event) {
        this.Form_AddUser_Latitude = event.coords.lat;
        this.Form_AddUser_Longitude = event.coords.lng;
    }
    public Form_AddUser_AddressChange(address: Address) {
        this.Form_AddUser_Latitude = address.geometry.location.lat();
        this.Form_AddUser_Longitude = address.geometry.location.lng();
        this.Form_AddUser_Address = address.formatted_address;
    }
}
