import { Injectable } from "@angular/core";
import * as cloneDeep from 'lodash/cloneDeep';
import { isNull, isUndefined } from "util";
import "../../assets/js/systemhelper.js";
import { HelperService } from "./helper.service";
import { OList } from "./object.service.js";
declare var $: any;
declare var moment: any;
declare var SystemHelper: any;

@Injectable()
export class FilterHelperService {
  constructor(public _HelperService: HelperService) { }

  //#region MerchantFilterConfig 

  _BuildFilterName_Merchant(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion    

    this._HelperService.FilterSnap.text = name;
  }

  SetUsageConfig(UsageList_Config: OList): void {


    if (
      !(isNull(this._HelperService.FilterSnap.Status) || isUndefined(this._HelperService.FilterSnap.Status))
    ) {
      UsageList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      UsageList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    } else {
      UsageList_Config.StartTime = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      UsageList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    } else {
      UsageList_Config.EndTime = null;
    }

    if (!(isNull(this._HelperService.FilterSnap.Sort) || isUndefined(this._HelperService.FilterSnap.Sort))) {
      UsageList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      UsageList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    } else {
      UsageList_Config.SearchCondition = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      UsageList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    } else {
      UsageList_Config.SearchBaseConditions = [];
    }

  }
  SetVersionConfig(VersionsList_Config: OList): void {


    if (
      !(isNull(this._HelperService.FilterSnap.Status) || isUndefined(this._HelperService.FilterSnap.Status))
    ) {
      VersionsList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      VersionsList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    } else {
      VersionsList_Config.StartTime = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      VersionsList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    } else {
      VersionsList_Config.EndTime = null;
    }

    if (!(isNull(this._HelperService.FilterSnap.Sort) || isUndefined(this._HelperService.FilterSnap.Sort))) {
      VersionsList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      VersionsList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    } else {
      VersionsList_Config.SearchCondition = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      VersionsList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    } else {
      VersionsList_Config.SearchBaseConditions = [];
    }

  }
  _RemoveFilter_Version(Type: string, index?: number): void {
    //#region RemoveFromFilterSnap 

    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnap.Sort.SortDefaultOrder = "desc";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnap.OtherFilters.splice(index, 1);
    }

    //#endregion

    //#region RemoveFromTemprarySnap 
    if (Type == "Time") {
      {
        this._HelperService.FilterSnapTemprary.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.StartTime = null;
      }
      {
        this._HelperService.FilterSnapTemprary.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnapTemprary.StatusType = "default";
      }
      {
        this._HelperService.FilterSnapTemprary.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultOrder = "desc";
        this._HelperService.FilterSnapTemprary.Sort.SortName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortColumn = null;
        this._HelperService.FilterSnapTemprary.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnapTemprary.OtherFilters.splice(index, 1);
    }

    //#endregion

    this._HelperService.ComputeFilterState();
  }
  _BuildFilterName_Version(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion    

    this._HelperService.FilterSnap.text = name;
  }
  _RemoveFilter_Usage(Type: string, index?: number): void {
    //#region RemoveFromFilterSnap 

    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "ResponseTime";
        this._HelperService.FilterSnap.Sort.SortDefaultOrder = "desc";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnap.OtherFilters.splice(index, 1);
    }

    //#endregion

    //#region RemoveFromTemprarySnap 
    if (Type == "Time") {
      {
        this._HelperService.FilterSnapTemprary.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.StartTime = null;
      }
      {
        this._HelperService.FilterSnapTemprary.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnapTemprary.StatusType = "default";
      }
      {
        this._HelperService.FilterSnapTemprary.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultColumn = "ResponseTime";
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultOrder = "desc";
        this._HelperService.FilterSnapTemprary.Sort.SortName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortColumn = null;
        this._HelperService.FilterSnapTemprary.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnapTemprary.OtherFilters.splice(index, 1);
    }

    //#endregion

    this._HelperService.ComputeFilterState();
  }
  _BuildFilterName_Usage(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion    

    this._HelperService.FilterSnap.text = name;
  }
  SetMerchantConfig(MerchantsList_Config: OList): void {


    if (
      !(isNull(this._HelperService.FilterSnap.Status) || isUndefined(this._HelperService.FilterSnap.Status))
    ) {
      MerchantsList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }



    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      MerchantsList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    }
    else {
      MerchantsList_Config.StartTime = null;
    }
    if (
      !(isNull(this._HelperService.FilterSnap.EndTime) || isUndefined(this._HelperService.FilterSnap.EndTime))
    ) {
      MerchantsList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    }
    else {
      MerchantsList_Config.EndTime = null;
    }


    if (!(isNull(this._HelperService.FilterSnap.Sort) || isUndefined(this._HelperService.FilterSnap.Sort))) {
      MerchantsList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      MerchantsList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    } else {
      MerchantsList_Config.SearchCondition = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      MerchantsList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    } else {
      MerchantsList_Config.SearchBaseConditions = [];
    }

  }

  _RemoveFilter_Merchant(Type: string, index?: number): void {
    //#region RemoveFromFilterSnap 

    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnap.OtherFilters.splice(index, 1);
    }

    //#endregion

    //#region RemoveFromTemprarySnap 
    if (Type == "Time") {
      {
        this._HelperService.FilterSnapTemprary.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.StartTime = null;
      }
      {
        this._HelperService.FilterSnapTemprary.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnapTemprary.StatusType = "default";
      }
      {
        this._HelperService.FilterSnapTemprary.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnapTemprary.Sort.SortName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortColumn = null;
        this._HelperService.FilterSnapTemprary.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnapTemprary.OtherFilters.splice(index, 1);
    }

    //#endregion
  }

  //#endregion

  //#region POSFilterConfig

  _BuildFilterName_POS(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion

    this._HelperService.FilterSnap.text = name;
  }

  SetPOSConfig(POSList_Config: OList): void {
    if (
      !(
        isNull(this._HelperService.FilterSnap.Status) ||
        isUndefined(this._HelperService.FilterSnap.Status)
      )
    ) {
      POSList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      POSList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    }
    else {
      POSList_Config.StartTime = null;
    }


    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      POSList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    }

    else {
      POSList_Config.StartTime = null;
    }


    if (
      !(
        isNull(this._HelperService.FilterSnap.Sort) ||
        isUndefined(this._HelperService.FilterSnap.Sort)
      )
    ) {
      POSList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      POSList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      POSList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    }
  }

  _RemoveFilter_POS(Type: string, index?: number): void {
    //#region RemoveFromFilterSnap 

    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnap.OtherFilters.splice(index, 1);
    }

    //#endregion

    //#region RemoveFromTemprarySnap 
    if (Type == "Time") {
      {
        this._HelperService.FilterSnapTemprary.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.StartTime = null;
      }
      {
        this._HelperService.FilterSnapTemprary.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnapTemprary.StatusType = "default";
      }
      {
        this._HelperService.FilterSnapTemprary.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnapTemprary.Sort.SortName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortColumn = null;
        this._HelperService.FilterSnapTemprary.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnapTemprary.OtherFilters.splice(index, 1);
    }

    //#endregion
  }

  //#endregion

  //#region StoreFilterConfig

  _BuildFilterName_Store(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion
    this._HelperService.FilterSnap.text = name;
  }

  SetStoreConfig(POSList_Config: OList): void {
    if (
      !(
        isNull(this._HelperService.FilterSnap.Status) ||
        isUndefined(this._HelperService.FilterSnap.Status)
      )
    ) {
      POSList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      POSList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);

    }
    else {
      POSList_Config.StartTime = null;
    }


    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      POSList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    }
    else {
      POSList_Config.EndTime = null;
    }


    if (
      !(
        isNull(this._HelperService.FilterSnap.Sort) ||
        isUndefined(this._HelperService.FilterSnap.Sort)
      )
    ) {
      POSList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      POSList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      POSList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    }
  }

  _RemoveFilter_Store(Type: string, index?: number): void {
    //#region RemoveFromFilterSnap 

    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnap.OtherFilters.splice(index, 1);
    }

    //#endregion

    //#region RemoveFromTemprarySnap 
    if (Type == "Time") {
      {
        this._HelperService.FilterSnapTemprary.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.StartTime = null;
      }
      {
        this._HelperService.FilterSnapTemprary.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnapTemprary.StatusType = "default";
      }
      {
        this._HelperService.FilterSnapTemprary.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnapTemprary.Sort.SortName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortColumn = null;
        this._HelperService.FilterSnapTemprary.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnapTemprary.OtherFilters.splice(index, 1);
    }

    //#endregion
  }

  //#endregion

  //#region BranchFilterConfig

  _BuildFilterName_Branch(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion
    this._HelperService.FilterSnap.text = name;
  }

  SetBranchConfig(POSList_Config: OList): void {
    if (
      !(
        isNull(this._HelperService.FilterSnap.Status) ||
        isUndefined(this._HelperService.FilterSnap.Status)
      )
    ) {
      POSList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      POSList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      POSList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.Sort) ||
        isUndefined(this._HelperService.FilterSnap.Sort)
      )
    ) {
      POSList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      POSList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    } else {
      POSList_Config.SearchCondition = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      POSList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    } else {
      POSList_Config.SearchBaseConditions = [];
    }
  }

  _RemoveFilter_Branch(Type: string): void {
    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
  }

  //#endregion

  //#region ManagerFilterConfig

  _BuildFilterName_Manager(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion
    this._HelperService.FilterSnap.text = name;
  }

  SetManagerConfig(POSList_Config: OList): void {
    if (
      !(
        isNull(this._HelperService.FilterSnap.Status) ||
        isUndefined(this._HelperService.FilterSnap.Status)
      )
    ) {
      POSList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      POSList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      POSList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.Sort) ||
        isUndefined(this._HelperService.FilterSnap.Sort)
      )
    ) {
      POSList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      POSList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    } else {
      POSList_Config.SearchCondition = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      POSList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    } else {
      POSList_Config.SearchBaseConditions = [];
    }
  }

  _RemoveFilter_Manager(Type: string): void {
    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
  }

  //#endregion

  //#region CampaignFilterConfig

  _BuildFilterName_Campaign(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion
    this._HelperService.FilterSnap.text = name;
  }

  SetCampaignConfig(POSList_Config: OList): void {
    if (
      !(
        isNull(this._HelperService.FilterSnap.Status) ||
        isUndefined(this._HelperService.FilterSnap.Status)
      )
    ) {
      POSList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      POSList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      POSList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.Sort) ||
        isUndefined(this._HelperService.FilterSnap.Sort)
      )
    ) {
      POSList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      POSList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    } else {
      POSList_Config.SearchCondition = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      POSList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    } else {
      POSList_Config.SearchBaseConditions = [];
    }
  }

  _RemoveFilter_Campaign(Type: string): void {
    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "CreateDate";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
  }

  //#endregion

  //#region MerchantSalesFilterConfig

  _BuildFilterName_MerchantSales(name: string): void {

    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion

    this._HelperService.FilterSnap.text = name;
  }

  SetMerchantSalesConfig(POSList_Config: OList): void {
    if (
      !(
        isNull(this._HelperService.FilterSnap.Status) ||
        isUndefined(this._HelperService.FilterSnap.Status)
      )
    ) {
      POSList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      POSList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    } else {
      POSList_Config.StartTime = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      POSList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    } else {
      POSList_Config.EndTime = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.Sort) ||
        isUndefined(this._HelperService.FilterSnap.Sort)
      )
    ) {
      POSList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      POSList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    } else {
      POSList_Config.SearchCondition = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      POSList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    } else {
      POSList_Config.SearchBaseConditions = [];
    }

    if (!(isNull(this._HelperService.FilterSnap.SalesRange) ||
      isUndefined(this._HelperService.FilterSnap.SalesRange))) {
      POSList_Config.SalesRange = cloneDeep(this._HelperService.FilterSnap.SalesRange);

    }

  }

  _RemoveFilter_MerchantSales(Type: string, index?: number): void {

    //#region RemoveFromFilterSnap 

    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "SalesRange") {
      this._HelperService.FilterSnap.SalesRange.SalesMin = 0;
      this._HelperService.FilterSnap.SalesRange.SalesMax = 10000000;
    }
    if (Type == "RewardRange") {
      this._HelperService.FilterSnap.SalesRange.RewardMin = 0;
      this._HelperService.FilterSnap.SalesRange.RewardMax = 10000000;
    }
    if (Type == "RedeemRange") {
      this._HelperService.FilterSnap.SalesRange.RedeemMin = 0;
      this._HelperService.FilterSnap.SalesRange.RedeemMax = 10000000;
    }
    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "TransactionDate";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnap.OtherFilters.splice(index, 1);
    }

    //#endregion

    //#region RemoveFromTemprarySnap 
    if (Type == "Time") {
      {
        this._HelperService.FilterSnapTemprary.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.StartTime = null;
      }
      {
        this._HelperService.FilterSnapTemprary.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnapTemprary.StatusType = "default";
      }
      {
        this._HelperService.FilterSnapTemprary.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultColumn = "TransactionDate";
        this._HelperService.FilterSnapTemprary.Sort.SortName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortColumn = null;
        this._HelperService.FilterSnapTemprary.Sort.SortOrder = "desc";
      }
    }

    if (Type == "SalesRange") {
      this._HelperService.FilterSnapTemprary.SalesRange.SalesMin = 0;
      this._HelperService.FilterSnapTemprary.SalesRange.SalesMax = 10000000;
    }
    if (Type == "RewardRange") {
      this._HelperService.FilterSnapTemprary.SalesRange.RewardMin = 0;
      this._HelperService.FilterSnapTemprary.SalesRange.RewardMax = 10000000;
    }
    if (Type == "RedeemRange") {
      this._HelperService.FilterSnapTemprary.SalesRange.RedeemMin = 0;
      this._HelperService.FilterSnapTemprary.SalesRange.RedeemMax = 10000000;
    }
    if (Type == "Other") {
      this._HelperService.FilterSnapTemprary.OtherFilters.splice(index, 1);
    }

    this._HelperService.ComputeFilterState();

    //#endregion

  }

  //#endregion

  //#region DealNotifFilterConfig

  _BuildFilterName_DealNotif(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion
    this._HelperService.FilterSnap.text = name;
  }

  SetDealNotifConfig(POSList_Config: OList): void {
    if (
      !(
        isNull(this._HelperService.FilterSnap.Status) ||
        isUndefined(this._HelperService.FilterSnap.Status)
      )
    ) {
      POSList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      POSList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    } else {
      POSList_Config.StartTime = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      POSList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    } else {
      POSList_Config.EndTime = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.Sort) ||
        isUndefined(this._HelperService.FilterSnap.Sort)
      )
    ) {
      POSList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      POSList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    }
    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      POSList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    } else {
      POSList_Config.SearchBaseConditions = [];
    }

    if (!(isNull(this._HelperService.FilterSnap.SalesRange) ||
      isUndefined(this._HelperService.FilterSnap.SalesRange))) {
      POSList_Config.SalesRange = cloneDeep(this._HelperService.FilterSnap.SalesRange);
    }

  }

  _RemoveFilter_DealNotif(Type: string, index?: number): void {
    //#region RemoveFromFilterSnap 

    if (Type == "Time") {
      {
        this._HelperService.FilterSnap.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnap.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnap.StartTime = null;
      }
      {
        this._HelperService.FilterSnap.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnap.StatusType = "default";
      }
      {
        this._HelperService.FilterSnap.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "Date";
        this._HelperService.FilterSnap.Sort.SortDefaultOrder = "desc";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnap.OtherFilters.splice(index, 1);
    }
    if (Type == "SalesRange") {
      this._HelperService.FilterSnap.SalesRange.SalesMin = 0;
      this._HelperService.FilterSnap.SalesRange.SalesMax = 10000000;
    }
    if (Type == "RewardRange") {
      this._HelperService.FilterSnap.SalesRange.RewardMin = 0;
      this._HelperService.FilterSnap.SalesRange.RewardMax = 10000000;
    }
    if (Type == "RedeemRange") {
      this._HelperService.FilterSnap.SalesRange.RedeemMin = 0;
      this._HelperService.FilterSnap.SalesRange.RedeemMax = 10000000;
    }

    //#endregion

    //#region RemoveFromTemprarySnap 
    if (Type == "Time") {
      {
        this._HelperService.FilterSnapTemprary.ShowingStart = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.ShowingEnd = 0;
      }
      {
        this._HelperService.FilterSnapTemprary.StartTime = null;
      }
      {
        this._HelperService.FilterSnapTemprary.EndTime = null;
      }
    }

    if (Type == "Status") {
      {
        this._HelperService.FilterSnapTemprary.StatusType = "default";
      }
      {
        this._HelperService.FilterSnapTemprary.Status = 0;
      }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultColumn = "Date";
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultOrder = "desc";
        this._HelperService.FilterSnapTemprary.Sort.SortName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortColumn = null;
        this._HelperService.FilterSnapTemprary.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnapTemprary.OtherFilters.splice(index, 1);
    }
    if (Type == "SalesRange") {
      this._HelperService.FilterSnapTemprary.SalesRange.SalesMin = 0;
      this._HelperService.FilterSnapTemprary.SalesRange.SalesMax = 10000000;
    }
    if (Type == "RewardRange") {
      this._HelperService.FilterSnapTemprary.SalesRange.RewardMin = 0;
      this._HelperService.FilterSnapTemprary.SalesRange.RewardMax = 10000000;
    }
    if (Type == "RedeemRange") {
      this._HelperService.FilterSnapTemprary.SalesRange.RedeemMin = 0;
      this._HelperService.FilterSnapTemprary.SalesRange.RedeemMax = 10000000;
    }

    //#endregion

    this._HelperService.ComputeFilterState();
  }

  //#endregion

  //#region RequestHistoryFilterConfig 

  _BuildFilterName_RequestHistory(name: string): void {
    //#region StorePreviousState 
    this._HelperService.FilterSnapPrev.id = this._HelperService.FilterSnap.id;
    this._HelperService.FilterSnapPrev.text = this._HelperService.FilterSnap.text;
    //#endregion
    this._HelperService.FilterSnap.text = name;
  }

  SetRequestHistoryConfig(POSList_Config: OList): void {
    if (
      !(
        isNull(this._HelperService.FilterSnap.Status) ||
        isUndefined(this._HelperService.FilterSnap.Status)
      )
    ) {
      POSList_Config.Status = cloneDeep(this._HelperService.FilterSnap.Status);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.StartTime) ||
        isUndefined(this._HelperService.FilterSnap.StartTime)
      )
    ) {
      POSList_Config.StartTime = cloneDeep(this._HelperService.FilterSnap.StartTime);
    } else {
      POSList_Config.StartTime = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.EndTime) ||
        isUndefined(this._HelperService.FilterSnap.EndTime)
      )
    ) {
      POSList_Config.EndTime = cloneDeep(this._HelperService.FilterSnap.EndTime);
    } else {
      POSList_Config.EndTime = null;
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.Sort) ||
        isUndefined(this._HelperService.FilterSnap.Sort)
      )
    ) {
      POSList_Config.Sort = cloneDeep(this._HelperService.FilterSnap.Sort);
    }

    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchCondition) ||
        isUndefined(this._HelperService.FilterSnap.SearchCondition)
      )
    ) {
      POSList_Config.SearchCondition = cloneDeep(this._HelperService.FilterSnap.SearchCondition);
    }
    if (
      !(
        isNull(this._HelperService.FilterSnap.SearchBaseConditions) ||
        isUndefined(this._HelperService.FilterSnap.SearchBaseConditions)
      )
    ) {
      POSList_Config.SearchBaseConditions = cloneDeep(this._HelperService.FilterSnap.SearchBaseConditions);
    } else {
      POSList_Config.SearchBaseConditions = [];
    }


  }

  _RemoveFilter_RequestHistory(Type: string, index?: number): void {
    //#region RemoveFromFilterSnap 

    if (Type == "Time") {
      { this._HelperService.FilterSnap.ShowingStart = 0; }
      { this._HelperService.FilterSnap.ShowingEnd = 0; }
      { this._HelperService.FilterSnap.StartTime = null; }
      { this._HelperService.FilterSnap.EndTime = null; }
    }

    if (Type == "Status") {
      { this._HelperService.FilterSnap.StatusType = "default"; }
      { this._HelperService.FilterSnap.Status = 0; }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnap.Sort.SortDefaultName = null;
        this._HelperService.FilterSnap.Sort.SortDefaultColumn = "RequestTime";
        this._HelperService.FilterSnap.Sort.SortDefaultOrder = "desc";
        this._HelperService.FilterSnap.Sort.SortName = null;
        this._HelperService.FilterSnap.Sort.SortColumn = null;
        this._HelperService.FilterSnap.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnap.OtherFilters.splice(index, 1);
    }

    //#endregion

    //#region RemoveFromTemprarySnap 
    if (Type == "Time") {
      { this._HelperService.FilterSnapTemprary.ShowingStart = 0; }
      { this._HelperService.FilterSnapTemprary.ShowingEnd = 0; }
      { this._HelperService.FilterSnapTemprary.StartTime = null; }
      { this._HelperService.FilterSnapTemprary.EndTime = null; }
    }

    if (Type == "Status") {
      { this._HelperService.FilterSnapTemprary.StatusType = "default"; }
      { this._HelperService.FilterSnapTemprary.Status = 0; }
    }

    if (Type == "Sort") {
      {
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultColumn = "RequestTime";
        this._HelperService.FilterSnapTemprary.Sort.SortDefaultOrder = "desc";
        this._HelperService.FilterSnapTemprary.Sort.SortName = null;
        this._HelperService.FilterSnapTemprary.Sort.SortColumn = null;
        this._HelperService.FilterSnapTemprary.Sort.SortOrder = "desc";
      }
    }
    if (Type == "Other") {
      this._HelperService.FilterSnapTemprary.OtherFilters.splice(index, 1);
    }

    //#endregion

    this._HelperService.ComputeFilterState();
  }

  //#endregion




  //# Navigation bar

  StoreDetail_Selected(_TerminalDetails: any) {

    var ReferenceData = _TerminalDetails
    ReferenceData.ReferenceKey = _TerminalDetails.StoreKey;
    ReferenceData.ReferenceId = _TerminalDetails.StoreId;

    this._HelperService.SaveStorage(
      this._HelperService.AppConfig.Storage.ActiveStore,
      {
        ReferenceKey: _TerminalDetails.StoreKey,
        ReferenceId: _TerminalDetails.StoreId,
        DisplayName: _TerminalDetails.StoreName,
        AccountTypeCode: this._HelperService.AppConfig.AccountType.Store,
      }
    );



    this._HelperService._Router.navigate([
      this._HelperService.AppConfig.Pages.ThankUCash.PanelPTSP.Store
        .Dashboard,
      ReferenceData.ReferenceKey,
      ReferenceData.ReferenceId,
    ]);
  }

  MerchantDetail_Selected(_TerminalDetails: any) {

    var ReferenceData = _TerminalDetails
    ReferenceData.ReferenceKey = _TerminalDetails.MerchantKey;
    ReferenceData.ReferenceId = _TerminalDetails.MerchantId;

    this._HelperService.SaveStorage(
      this._HelperService.AppConfig.Storage.ActiveMerchant,
      {
        ReferenceKey: _TerminalDetails.MerchantKey,
        ReferenceId: _TerminalDetails.MerchantId,
        DisplayName: _TerminalDetails.MerchantName,
        AccountTypeCode: this._HelperService.AppConfig.AccountType.Merchant,
      }
    );



    this._HelperService._Router.navigate([
      this._HelperService.AppConfig.Pages.ThankUCash.PanelPTSP.Merchant
        .Dashboard,
      ReferenceData.ReferenceKey,
      ReferenceData.ReferenceId,
    ]);
  }



}
