import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  constructor(
    private _TranslateService: TranslateService,
  ) {
    _TranslateService.addLangs(['en']);
    _TranslateService.setDefaultLang('en');
    const browserLang = _TranslateService.getBrowserLang();
    _TranslateService.use(browserLang.match(/en/) ? browserLang : 'en');
    

  }


  ngOnInit(){
    Feather.replace();
  }
  

}
