import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { OResponse, OListResponse, OSelect, OList, } from './object.service';
import { HelperService } from './helper.service';
import { isDefined } from '@angular/compiler/src/util';
import { isNull } from 'util';
import { Icon } from 'leaflet';
declare var $: any;
declare var moment: any;
@Injectable()
export class DataHelperService {
    constructor(
        public _TranslateService: TranslateService,
        public _HelperService: HelperService) {

    }
    S2_BuildList(S2_Options: OSelect) {
        var Id = [];
        var Text = [];
        var Transport = {
            delay: 200,
            data: (params: any) => {
                return {
                    search: params.term,
                    page: params.page || 1
                };
            },
            transport: (params: any, success: any, failure: any) => {
                var SearchContent = "";
                if (params.data.search != undefined) {
                    SearchContent = params.data.search;
                }
                Id = [];
                Text = [];
                var SearchData = '';
                S2_Options.Fields.forEach(Item => {
                    if (Item.Id == true) {
                        Id.push(Item.SystemName)
                    }
                    if (Item.Text == true) {
                        Text.push(Item.SystemName)
                    }
                    if (Item.SearchCondition != undefined && Item.SearchValue != undefined) {
                        SearchData = this._HelperService.GetSearchConditionStrict(SearchData, Item.SystemName, Item.Type, Item.SearchValue, Item.SearchCondition);
                    }
                    else {
                        SearchData = this._HelperService.GetSearchCondition(SearchData, Item.SystemName, Item.Type, SearchContent);
                    }
                });
                if (S2_Options.SearchCondition != undefined && S2_Options.SearchCondition != null && S2_Options.SearchCondition != '') {
                    if (SearchData != undefined && SearchData != null && SearchData != "") {
                        SearchData = "(" + S2_Options.SearchCondition + ") AND " + "(" + SearchData + ")";
                    }
                    else {
                        SearchData = "(" + S2_Options.SearchCondition + ")";
                    }
                }
                var SortCondition = null;
                if (S2_Options.SortCondition != null) {
                    SortCondition = this._HelperService.GetSortCondition(S2_Options.SortCondition);
                }
                else {
                    SortCondition = this._HelperService.GetSortCondition([Text[0] + ' asc']);
                }
                var S2Response = this.S2_GetResults(params.data.page, SearchData, S2_Options.Location, S2_Options.Task, success, failure, SortCondition, S2_Options.ReferenceId, S2_Options.ReferenceKey);
            },
            processResults: (data: any, params: any) => {
                return this.S2_ProcessData(data, params, Text, Id);
            }
        };
        return Transport;
    }
    S2_ProcessData(data: any, params: any, NameParameters: any[], ValueParameters: any[]) {
        var MoreResults = false;
        var Offset = 0;
        var FData;
        if (data != undefined && data != null) {
            if (params.page == undefined) {
                Offset = data.Limit;
            }
            else {
                Offset = params.page * data.Limit;
            }
            MoreResults = false;
            var FData = $.map(data.Data, function (obj) {
                if (NameParameters.length == 1) {
                    obj.text = obj[NameParameters[0]];
                }
                else {
                    var NameContent = '';
                    for (let index = 0; index < NameParameters.length; index++) {
                        const element = NameParameters[index];
                        if (NameContent == '') {
                            if (obj[element] != undefined) {
                                NameContent += obj[element];
                            }
                        }
                        else {
                            if (obj[element] != undefined) {
                                NameContent += ' - ' + obj[element];
                            }
                        }
                    }
                    obj.text = NameContent;
                }
                if (ValueParameters.length == 1) {
                    obj.id = obj[ValueParameters[0]];
                }
                else {
                    var IdContent = '';
                    for (let index = 0; index < ValueParameters.length; index++) {
                        const element = ValueParameters[index];
                        if (IdContent == '') {
                            IdContent += obj[element];
                        }
                        else {
                            IdContent += ' - ' + obj[element];
                        }
                    }
                    obj.text = NameContent;
                }
                return obj;
            });
            params.page = params.page || 1;
            return {
                results: FData,
                pagination: {
                    more: MoreResults,
                }
            };
        }
        else {
            Offset = 1;
            FData = [];
            return {
                results: FData,
                pagination: {
                    more: 0,
                }
            };
        }
    }
    S2_GetResults(Offset, SearchContent, NetworkApiLocation, NetworkApi, success: any, failure: any, SortCondition?: any, ReferenceId?: any, ReferenceKey?: any) {
        var PData = {
            Task: NetworkApi,
            Offset: (Offset - 1) * this._HelperService.AppConfig.DropDownListLimit,
            Limit: this._HelperService.AppConfig.DropDownListLimit,
            RefreshCount: false,
            SearchCondition: SearchContent,
            SortExpression: SortCondition,
            ReferenceId: ReferenceId,
            ReferenceKey: ReferenceKey,
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(NetworkApiLocation, PData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    var _ResponseData = _Response.Result;
                    success(_ResponseData);
                }
                else {
                    success(_ResponseData);
                }
            },
            _Error => {
                failure();
            });
    }
    List_Initialize(ListOptions: OList) {
        if (ListOptions.Task == undefined) { ListOptions.Task = null; }
        if (ListOptions.ReferenceKey == undefined) { ListOptions.ReferenceKey = null; }
        if (ListOptions.Type == undefined) { ListOptions.Type = null; }
        if (ListOptions.Location == undefined) { ListOptions.Location = null; }
        if (ListOptions.Title == undefined) { ListOptions.Title = 'List'; }
        if (ListOptions.TableFields == undefined) { ListOptions.TableFields = []; }
        if (ListOptions.StatusOptions == undefined) { ListOptions.StatusOptions = []; }
        if (ListOptions.TitleResourceId == undefined) { ListOptions.TitleResourceId = null; }
        if (ListOptions.RefreshData == undefined) { ListOptions.RefreshData = false; }
        if (ListOptions.IsDownload == undefined) { ListOptions.IsDownload = false; }
        if (ListOptions.ActivePage == undefined) { ListOptions.ActivePage = 1; }
        if (ListOptions.PageRecordLimit == undefined) { ListOptions.PageRecordLimit = this._HelperService.AppConfig.ListRecordLimit[0]; }
        if (ListOptions.TotalRecords == undefined) { ListOptions.TotalRecords = 0; }
        if (ListOptions.ShowingStart == undefined) { ListOptions.ShowingStart = 0; }
        if (ListOptions.ShowingEnd == undefined) { ListOptions.ShowingEnd = 0; }
        if (ListOptions.StartTime == undefined) { ListOptions.StartTime = null; }
        if (ListOptions.EndTime == undefined) { ListOptions.EndTime = null; }
        if (ListOptions.ReferenceId == undefined) { ListOptions.ReferenceId = 0; }
        if (ListOptions.SubReferenceId == undefined) { ListOptions.SubReferenceId = 0; }
        if (ListOptions.SearchParameter == undefined) { ListOptions.SearchParameter = null; }
        if (ListOptions.SearchCondition == undefined) { ListOptions.SearchCondition = null; }
        if (ListOptions.SearchBaseCondition == undefined) { ListOptions.SearchBaseCondition = null; }
        if (ListOptions.SearchBaseConditions == undefined) { ListOptions.SearchBaseConditions = []; }
        if (ListOptions.Data == undefined) { ListOptions.Data = []; }
        if (ListOptions.StatusType == undefined) { ListOptions.StatusType = 'default'; }
        if (ListOptions.Status == undefined) { ListOptions.Status = 0; }
        if (ListOptions.VisibleHeaders == undefined) { ListOptions.VisibleHeaders = []; }
        if (ListOptions.Sort == undefined) {
            ListOptions.Sort =
            {
                SortDefaultName: null,
                SortDefaultColumn: 'CreateDate',
                SortName: null,
                SortColumn: null,
                SortOrder: 'desc',
                SortOptions: [],
            }
        }
        ListOptions.Sort =
        {
            SortDefaultName: ListOptions.Sort.SortDefaultName,
            SortDefaultColumn: ListOptions.Sort.SortDefaultColumn,
            SortDefaultOrder: ListOptions.Sort.SortDefaultOrder,
            SortName: ListOptions.Sort.SortName,
            SortColumn: ListOptions.Sort.SortColumn,
            SortOrder: ListOptions.Sort.SortOrder,
            SortOptions: [],
        }
        if (ListOptions.Sort.SortDefaultOrder == undefined || ListOptions.Sort.SortDefaultOrder == null) {
            ListOptions.Sort.SortDefaultOrder = 'desc';
        }

        if (ListOptions.Sort.SortName == undefined || ListOptions.Sort.SortName == null || ListOptions.Sort.SortName == '') {
            ListOptions.Sort.SortName = ListOptions.Sort.SortDefaultName;
        }
        if (ListOptions.Sort.SortColumn == undefined || ListOptions.Sort.SortColumn == null || ListOptions.Sort.SortColumn == '') {
            ListOptions.Sort.SortColumn = ListOptions.Sort.SortDefaultColumn;
        }
        if (ListOptions.Sort.SortOrder == undefined || ListOptions.Sort.SortOrder == null || ListOptions.Sort.SortOrder == '') {
            ListOptions.Sort.SortOrder = ListOptions.Sort.SortDefaultOrder;
        }
        ListOptions.StatusOptions = this._HelperService.AppConfig.StatusList[ListOptions.StatusType];
        this.S2_Status_Option = {
            multiple: false,
            placeholder: "Filter by status"
        };
        this.S2_NewSavedFilters_Option = {
            multiple: false,
            placeholder: "Default"
        };
        this.S2_SavedFilters_Option = {
            multiple: false,
            placeholder: "Filter by status"
        };
        this.S2_SavedFilters_Option = {
            multiple: false,
            placeholder: "Filter by status"
        };
        this.S2_Sort_Option = {
            multiple: false,
            placeholder: "Sort results"
        };
        if (ListOptions.TitleResourceId != undefined && ListOptions.TitleResourceId != null && ListOptions.TitleResourceId != '') {
            this._TranslateService.get(ListOptions.TitleResourceId).subscribe((_Message: string) => {
                ListOptions.Title = _Message;
            });
        }
        ListOptions.TableFields.forEach(element => {
            if (element.ResourceId != undefined && element.ResourceId != null && element.ResourceId != '') {
                this._TranslateService.get(element.ResourceId).subscribe((_Message: string) => {
                    element.DisplayName = _Message;
                });
            }
            ListOptions.TableFields.push(element);
            if (element.Sort == true) {
                var FilterItem =
                {
                    Name: element.DisplayName,
                    SystemName: element.SystemName,
                    SystemActive: false

                }
                ListOptions.Sort.SortOptions.push(FilterItem);
            }
            if (element.Show == true) {
                ListOptions.VisibleHeaders.push(element);
                var Option =
                {
                    id: element.SystemName,
                    text: element.DisplayName,
                }
            }
        });
        return ListOptions;
    }

    List_Initialize_ForStores(ListOptions: OList) {
        if (ListOptions.Task == undefined) { ListOptions.Task = null; }
        if (ListOptions.ReferenceKey == undefined) { ListOptions.ReferenceKey = null; }
        if (ListOptions.Type == undefined) { ListOptions.Type = null; }
        if (ListOptions.Location == undefined) { ListOptions.Location = null; }
        if (ListOptions.Title == undefined) { ListOptions.Title = 'List'; }
        if (ListOptions.TableFields == undefined) { ListOptions.TableFields = []; }
        if (ListOptions.StatusOptions == undefined) { ListOptions.StatusOptions = []; }
        if (ListOptions.TitleResourceId == undefined) { ListOptions.TitleResourceId = null; }
        if (ListOptions.RefreshData == undefined) { ListOptions.RefreshData = false; }
        if (ListOptions.IsDownload == undefined) { ListOptions.IsDownload = false; }
        if (ListOptions.ActivePage == undefined) { ListOptions.ActivePage = 1; }
        if (ListOptions.PageRecordLimit == undefined) { ListOptions.PageRecordLimit = this._HelperService.AppConfig.ListRecordLimit[0]; }
        if (ListOptions.TotalRecords == undefined) { ListOptions.TotalRecords = 0; }
        if (ListOptions.ShowingStart == undefined) { ListOptions.ShowingStart = 0; }
        if (ListOptions.ShowingEnd == undefined) { ListOptions.ShowingEnd = 0; }
        if (ListOptions.StartTime == undefined) { ListOptions.StartTime = null; }
        if (ListOptions.EndTime == undefined) { ListOptions.EndTime = null; }
        if (ListOptions.ReferenceId == undefined) { ListOptions.ReferenceId = 0; }
        if (ListOptions.SubReferenceId == undefined) { ListOptions.SubReferenceId = 0; }
        if (ListOptions.SubReferenceKey == undefined) { ListOptions.SubReferenceKey = null; }
        if (ListOptions.SearchParameter == undefined) { ListOptions.SearchParameter = null; }
        if (ListOptions.SearchCondition == undefined) { ListOptions.SearchCondition = null; }
        if (ListOptions.SearchBaseCondition == undefined) { ListOptions.SearchBaseCondition = null; }
        if (ListOptions.SearchBaseConditions == undefined) { ListOptions.SearchBaseConditions = []; }
        if (ListOptions.Data == undefined) { ListOptions.Data = []; }
        if (ListOptions.StatusType == undefined) { ListOptions.StatusType = 'default'; }
        if (ListOptions.Status == undefined) { ListOptions.Status = 0; }
        if (ListOptions.VisibleHeaders == undefined) { ListOptions.VisibleHeaders = []; }
        if (ListOptions.Sort == undefined) {
            ListOptions.Sort =
            {
                SortDefaultName: null,
                SortDefaultColumn: 'CreateDate',
                SortName: null,
                SortColumn: null,
                SortOrder: 'desc',
                SortOptions: [],
            }
        }
        ListOptions.Sort =
        {
            SortDefaultName: ListOptions.Sort.SortDefaultName,
            SortDefaultColumn: ListOptions.Sort.SortDefaultColumn,
            SortDefaultOrder: ListOptions.Sort.SortDefaultOrder,
            SortName: ListOptions.Sort.SortName,
            SortColumn: ListOptions.Sort.SortColumn,
            SortOrder: ListOptions.Sort.SortOrder,
            SortOptions: [],
        }
        if (ListOptions.Sort.SortDefaultOrder == undefined || ListOptions.Sort.SortDefaultOrder == null) {
            ListOptions.Sort.SortDefaultOrder = 'desc';
        }

        if (ListOptions.Sort.SortName == undefined || ListOptions.Sort.SortName == null || ListOptions.Sort.SortName == '') {
            ListOptions.Sort.SortName = ListOptions.Sort.SortDefaultName;
        }
        if (ListOptions.Sort.SortColumn == undefined || ListOptions.Sort.SortColumn == null || ListOptions.Sort.SortColumn == '') {
            ListOptions.Sort.SortColumn = ListOptions.Sort.SortDefaultColumn;
        }
        if (ListOptions.Sort.SortOrder == undefined || ListOptions.Sort.SortOrder == null || ListOptions.Sort.SortOrder == '') {
            ListOptions.Sort.SortOrder = ListOptions.Sort.SortDefaultOrder;
        }
        ListOptions.StatusOptions = this._HelperService.AppConfig.StatusList[ListOptions.StatusType];
        this.S2_Status_Option = {
            multiple: false,
            placeholder: "Filter by status"
        };
        this.S2_NewSavedFilters_Option = {
            multiple: false,
            placeholder: "Filter by status"
        };
        this.S2_Sort_Option = {
            multiple: false,
            placeholder: "Sort results"
        };
        if (ListOptions.TitleResourceId != undefined && ListOptions.TitleResourceId != null && ListOptions.TitleResourceId != '') {
            this._TranslateService.get(ListOptions.TitleResourceId).subscribe((_Message: string) => {
                ListOptions.Title = _Message;
            });
        }
        ListOptions.TableFields.forEach(element => {
            if (element.ResourceId != undefined && element.ResourceId != null && element.ResourceId != '') {
                this._TranslateService.get(element.ResourceId).subscribe((_Message: string) => {
                    element.DisplayName = _Message;
                });
            }
            ListOptions.TableFields.push(element);
            if (element.Sort == true) {
                var FilterItem =
                {
                    Name: element.DisplayName,
                    SystemName: element.SystemName,
                    SystemActive: false

                }
                ListOptions.Sort.SortOptions.push(FilterItem);
            }
            if (element.Show == true) {
                ListOptions.VisibleHeaders.push(element);
                var Option =
                {
                    id: element.SystemName,
                    text: element.DisplayName,
                }
            }
        });
        // console.log(ListOptions);
        return ListOptions;
    }

    List_Operations(ListOptions: OList, event: any, Type: any, FilterType?: any) {
        var ResetOffset = false;
        ListOptions.RefreshData = false;
        ListOptions.RefreshCount = false;
        if (Type == this._HelperService.AppConfig.ListToggleOption.Limit) {
            ListOptions.PageRecordLimit = event;
            ResetOffset = true;
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.Search) {
            if (event != null) {
                ListOptions.SearchParameter = event.target.value;
            }
            ResetOffset = true;
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.Date) {
            if (ListOptions.StartDate == undefined && ListOptions.EndDate == undefined) {
                ListOptions.StartTime = event.start;
                ListOptions.EndTime = event.end;
            }
            ResetOffset = true;
            ListOptions.RefreshData = false;
            ListOptions.RefreshCount = false;
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.Status) {
            if ((event.data.length > 0) && (event.data[0].code != undefined) && event.data[0].code.includes('default')) {
                ListOptions.Status = event.data[0].code;
            } else {
                ListOptions.Status = event.value;
            }

            if (FilterType && (FilterType == this._HelperService.AppConfig.FilterTypeOption.Terminal)) {
                ListOptions.Status = event.value;
            }

            ListOptions.RefreshData = false;
            ListOptions.RefreshCount = false;
            ResetOffset = false;
        }

        else if (Type == this._HelperService.AppConfig.ListToggleOption.Sort) {
            ListOptions.Sort.SortColumn = event.SystemName;
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
            ResetOffset = true;
            $('#' + ListOptions.Id + "_sdropdown").dropdown('toggle');
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.SortOrder) {
            if (ListOptions.Sort.SortOrder == 'asc') {
                ListOptions.Sort.SortOrder = 'desc';
            }
            else {
                ListOptions.Sort.SortOrder = 'asc';
            }
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
            ResetOffset = true;
            $('#' + ListOptions.Id + "_sdropdown").dropdown('toggle');
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.SortApply) {
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
            ResetOffset = true;
            $('#' + ListOptions.Id + "_sdropdown").dropdown('toggle');
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.SortReset) {
            ListOptions.Sort.SortOrder = 'desc';
            ListOptions.Sort.SortColumn = null;
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
            ResetOffset = true;
            $('#' + ListOptions.Id + "_sdropdown").dropdown('toggle');
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.Page) {
            ListOptions.ActivePage = event;
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = false;

        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.MultiSelect) {
            ListOptions.ActivePage = event;
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;

        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.Refresh) {
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.Refresh) {
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.ResetOffset) {
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
            ResetOffset = true;
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.Csv) {
            this.List_DownloadCsv(ListOptions);
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.ApplyFilter) {
            ResetOffset = true;
            ListOptions.RefreshData = true;
            ListOptions.RefreshCount = true;
            $('#' + ListOptions.Id + "_fdropdown").dropdown('toggle');
        }
        else if (Type == this._HelperService.AppConfig.ListToggleOption.CancelFilter) {
            $('#' + ListOptions.Id + "_fdropdown").dropdown('toggle');
        }
        if (ResetOffset == true) {
            ListOptions.ActivePage = 1;
            ListOptions.TotalRecords = 0;
            ListOptions.ShowingStart = 0;
            ListOptions.ShowingEnd = 0;
        }

        // var isTypeApplyFilter = (Type == this._HelperService.AppConfig.ListToggleOption.ApplyFilter);
        // if (isTypeApplyFilter) {
        //     ListOptions.RefreshData = true;
        // } else {
        //     ListOptions.RefreshData = false;
        // }

        return ListOptions;
    }
    List_GetSearchCondition(ListOptions: OList) {
        var DateSearchFieldName = null;
        ListOptions.SearchCondition = '';
        if (ListOptions.TableFields != undefined) {
            ListOptions.TableFields.forEach(element => {
                if (element.IsDateSearchField == true) {
                    DateSearchFieldName = element.SystemName;
                }
                if (element.Search == true) {
                    ListOptions.SearchCondition = this._HelperService.GetSearchCondition(ListOptions.SearchCondition, element.SystemName, element.DataType, ListOptions.SearchParameter);
                }
            });
        }
        if (ListOptions.Status != 0) {
            ListOptions.SearchCondition = this._HelperService.GetSearchConditionStrict(ListOptions.SearchCondition, 'StatusCode', 'text', ListOptions.Status, "=");
        }
        if (DateSearchFieldName != null) {
            ListOptions.SearchCondition = this._HelperService.GetDateCondition(ListOptions.SearchCondition, DateSearchFieldName, ListOptions.StartTime, ListOptions.EndTime);
        }
        if (ListOptions.SearchBaseCondition != undefined && ListOptions.SearchBaseCondition != null && ListOptions.SearchBaseCondition != '') {
            if (ListOptions.SearchCondition != undefined && ListOptions.SearchCondition != null && ListOptions.SearchCondition != '') {
                ListOptions.SearchCondition = '(' + ListOptions.SearchCondition + ') AND (' + ListOptions.SearchBaseCondition + ')';
            }
            else {
                ListOptions.SearchCondition = ListOptions.SearchBaseCondition;
            }
        }
        if (ListOptions.SearchBaseConditions != undefined && ListOptions.SearchBaseConditions.length > 0) {
            ListOptions.SearchBaseConditions.forEach(element => {
                if (ListOptions.SearchCondition != undefined && ListOptions.SearchCondition != null && ListOptions.SearchCondition != '') {
                    ListOptions.SearchCondition = '(' + ListOptions.SearchCondition + ') AND (' + element + ')';
                }
                else {
                    ListOptions.SearchCondition = element;
                }
            });

        }
        return ListOptions;
    }


    List_GetSearchConditionTur(ListOptions: OList) {
        var DateSearchFieldName = null;
        ListOptions.SearchCondition = '';
        if (ListOptions.TableFields != undefined) {
            ListOptions.TableFields.forEach(element => {
                if (element.IsDateSearchField == true) {
                    DateSearchFieldName = element.SystemName;
                }
                if (element.Search == true) {
                    ListOptions.SearchCondition = this._HelperService.GetSearchCondition(ListOptions.SearchCondition, element.SystemName, element.DataType, ListOptions.SearchParameter);
                }
            });
        }
        if (ListOptions.Status != 0) {
            ListOptions.SearchCondition = this._HelperService.GetSearchConditionStrict(ListOptions.SearchCondition, 'StatusId', this._HelperService.AppConfig.DataType.Number, ListOptions.Status, "=");
        }
        if (DateSearchFieldName != null) {
            ListOptions.SearchCondition = this._HelperService.GetDateCondition(ListOptions.SearchCondition, DateSearchFieldName, ListOptions.StartTime, ListOptions.EndTime);
        }
        if (ListOptions.SearchBaseCondition != undefined && ListOptions.SearchBaseCondition != null && ListOptions.SearchBaseCondition != '') {
            if (ListOptions.SearchCondition != undefined && ListOptions.SearchCondition != null && ListOptions.SearchCondition != '') {
                ListOptions.SearchCondition = '(' + ListOptions.SearchCondition + ') AND (' + ListOptions.SearchBaseCondition + ')';
            }
            else {
                ListOptions.SearchCondition = ListOptions.SearchBaseCondition;
            }
        }
        if (ListOptions.SearchBaseConditions != undefined && ListOptions.SearchBaseConditions.length > 0) {
            ListOptions.SearchBaseConditions.forEach(element => {
                if (ListOptions.SearchCondition != undefined && ListOptions.SearchCondition != null && ListOptions.SearchCondition != '') {
                    ListOptions.SearchCondition = '(' + ListOptions.SearchCondition + ') AND (' + element + ')';
                }
                else {
                    ListOptions.SearchCondition = element;
                }
            });

        }
        return ListOptions;
    }

    List_GetSearchConditionTerm(ListOptions: OList) {
        var DateSearchFieldName = null;
        ListOptions.SearchCondition = '';
        if (ListOptions.TableFields != undefined) {
            ListOptions.TableFields.forEach(element => {
                if (element.IsDateSearchField == true) {
                    DateSearchFieldName = element.SystemName;
                }
                if (element.Search == true) {
                    ListOptions.SearchCondition = this._HelperService.GetSearchCondition(ListOptions.SearchCondition, element.SystemName, element.DataType, ListOptions.SearchParameter);
                }
            });
        }
        if (ListOptions.Status != 0) {
            ListOptions.SearchCondition = this._HelperService.GetSearchConditionStrict(ListOptions.SearchCondition, 'StatusId', this._HelperService.AppConfig.DataType.Number, ListOptions.Status, "=");
        }
        if (DateSearchFieldName != null) {
            ListOptions.SearchCondition = this._HelperService.GetDateCondition(ListOptions.SearchCondition, DateSearchFieldName, ListOptions.StartTime, ListOptions.EndTime);
        }
        if (ListOptions.SearchBaseCondition != undefined && ListOptions.SearchBaseCondition != null && ListOptions.SearchBaseCondition != '') {
            if (ListOptions.SearchCondition != undefined && ListOptions.SearchCondition != null && ListOptions.SearchCondition != '') {
                ListOptions.SearchCondition = '(' + ListOptions.SearchCondition + ') AND (' + ListOptions.SearchBaseCondition + ')';
            }
            else {
                ListOptions.SearchCondition = ListOptions.SearchBaseCondition;
            }
        }
        if (ListOptions.SearchBaseConditions != undefined && ListOptions.SearchBaseConditions.length > 0) {
            ListOptions.SearchBaseConditions.forEach(element => {
                if (ListOptions.SearchCondition != undefined && ListOptions.SearchCondition != null && ListOptions.SearchCondition != '') {
                    ListOptions.SearchCondition = '(' + ListOptions.SearchCondition + ') AND (' + element + ')';
                }
                else {
                    ListOptions.SearchCondition = element;
                }
            });

        }
        return ListOptions;
    }

    List_FormatResponse(ListOptions: OList, ResponseData: OListResponse, Show?: any) {
        ListOptions.Data = [];
        ListOptions.ShowingStart = ((ListOptions.ActivePage - 1) * ListOptions.PageRecordLimit) + 1;
        ListOptions.ShowingEnd = (ListOptions.ShowingStart + ListOptions.PageRecordLimit) - 1;
        ListOptions.TotalRecords = ResponseData.TotalRecords;
        var Result: any[] = ResponseData.Data;

        if (Show != undefined && Show != null) {
            if (Result.length == 0) {
                Show.ListShow = 'none';
                Show.IconShow = 'block';
            } else {
                Show.ListShow = 'block';
                Show.IconShow = 'none';
            }
        }


        for (var index = 0; index < Result.length; index++) {
            var element = Result[index];
            if (Result[index].ProcessingTime != undefined) {
                Result[index].ProcessingTime = (Result[index].ProcessingTime / 1000);
            }
            Result[index].StartDate = this._HelperService.GetDateS(element.StartDate);
            Result[index].EndDate = this._HelperService.GetDateS(element.EndDate);
            Result[index].LastUseDate = this._HelperService.GetDateTimeS(element.LastUseDate);
            Result[index].ActivityDate = this._HelperService.GetDateTimeS(element.ActivityDate);
            Result[index].LastTransactionDateDiff = this._HelperService.GetTimeDifferenceS(element.LastTransactionDate, moment());
            Result[index].TransactionDateDiff = this._HelperService.GetTimeDifferenceS(element.TransactionDate, moment());
            Result[index].TransactionDateD = this._HelperService.GetDateS(element.TransactionDate);
            Result[index].TransactionDateT = this._HelperService.GetTimeS(element.TransactionDate);
            Result[index].TransactionDate = this._HelperService.GetDateTimeS(element.TransactionDate);
            Result[index].VerifyDate = this._HelperService.GetDateTimeS(element.VerifyDate);
            Result[index].InvoiceDate = this._HelperService.GetDateS(element.InvoiceDate);
            Result[index].RequestTime = this._HelperService.GetDateTimeS(element.RequestTime);
            Result[index].ResponseTime = this._HelperService.GetDateTimeS(element.ResponseTime);
            Result[index].CreateDateD = this._HelperService.GetDateS(element.CreateDate);
            Result[index].CreateDateT = this._HelperService.GetTimeS(element.CreateDate);
            Result[index].CreateDate = this._HelperService.GetDateTimeS(element.CreateDate);
            Result[index].Date = this._HelperService.GetDateS(element.Date);
            Result[index].ModifyDate = this._HelperService.GetDateTimeS(element.ModifyDate);
            Result[index].LoginDate = this._HelperService.GetDateTimeS(element.LoginDate);
            if (Result[index].LastLoginDate != "0001-01-01T00:00:00") {
                Result[index].LastLoginDate = this._HelperService.GetDateTimeS(element.LastLoginDate);
            }
            else {
                Result[index].LastLoginDate = null;
            }
            if (Result[index].LastTransactionDate != "0001-01-01T00:00:00") {
                Result[index].LastTransactionDate = this._HelperService.GetDateTimeS(element.LastTransactionDate);
            }
            else {
                Result[index].LastTransactionDate = "";
            }
            Result[index].LogoutDate = this._HelperService.GetDateTimeS(element.LogoutDate);

            if (element.StatusId != undefined && element.StatusId != null) {
                Result[index].StatusI = this._HelperService.GetStatusIcon(element.StatusId);
                Result[index].StatusBadge = this._HelperService.GetStatusBadge(element.StatusId);
                Result[index].StatusC = this._HelperService.GetStatusColor(element.StatusId);
            } else {
                Result[index].StatusI = this._HelperService.GetStatusIcon(element.StatusCode);
                Result[index].StatusBadge = this._HelperService.GetStatusBadge(element.StatusCode);
                Result[index].StatusC = this._HelperService.GetStatusColor(element.StatusCode);
            }

            if (element.ApplicationStatusId != undefined) {
                Result[index].ApplStatusBadge = this._HelperService.GetStatusBadge(element.ApplicationStatusId);
            }

        }
        ListOptions.Data = Result;
        return ListOptions;
    }

    List_GetData(ListOptions: OList, Show?: any): OList {

        if (Show != undefined && Show != null) {
            Show.ListShow = 'none';
            Show.IconShow = 'block';
        }

        ListOptions.SearchCondition = '';
        ListOptions = this.List_GetSearchCondition(ListOptions);
        if (ListOptions.ActivePage == 1) {
            ListOptions.RefreshCount = true;
        }
        var SortExpression = ListOptions.Sort.SortDefaultColumn + ' ' + ListOptions.Sort.SortDefaultOrder;;

        if (ListOptions.Sort.SortDefaultName) {
            ListOptions.Sort.SortDefaultName = ListOptions.Sort.SortDefaultName.replace(' desc', '');
            ListOptions.Sort.SortDefaultName = ListOptions.Sort.SortDefaultName.replace(' asc', '');
            ListOptions.Sort.SortDefaultName = ListOptions.Sort.SortDefaultName + ' ' + ListOptions.Sort.SortDefaultOrder;
        }

        if (ListOptions.Sort.SortColumn != undefined && ListOptions.Sort.SortColumn != null && ListOptions.Sort.SortColumn != '') {
            if (ListOptions.Sort.SortOrder != undefined && ListOptions.Sort.SortOrder != null && ListOptions.Sort.SortOrder != '') {
                SortExpression = ListOptions.Sort.SortColumn + ' ' + ListOptions.Sort.SortOrder;
            }
            else {
                SortExpression = ListOptions.Sort.SortColumn + ' desc';
            }
        }

        var pData = {
            Task: ListOptions.Task,
            TotalRecords: ListOptions.TotalRecords,
            Offset: (ListOptions.ActivePage - 1) * ListOptions.PageRecordLimit,
            Limit: ListOptions.PageRecordLimit,
            RefreshCount: ListOptions.RefreshCount,
            SearchCondition: ListOptions.SearchCondition,
            SortExpression: SortExpression,
            Type: ListOptions.Type,
            ReferenceKey: ListOptions.ReferenceKey,
            StartDate: ListOptions.StartDate,
            EndDate: ListOptions.EndDate,
            ReferenceId: ListOptions.ReferenceId,
            SubReferenceId: ListOptions.SubReferenceId,
            SubReferenceKey: ListOptions.SubReferenceKey,
            AccountId: ListOptions.AccountId,
            AccountKey: ListOptions.AccountKey,
            ListType: ListOptions.ListType,
            IsDownload: false,
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(ListOptions.Location, pData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {

                    if (Show != undefined && Show != null) {
                        ListOptions = this.List_FormatResponse(ListOptions, _Response.Result, Show);
                    } else {
                        ListOptions = this.List_FormatResponse(ListOptions, _Response.Result);
                    }

                    return ListOptions;
                }
                else {
                    ListOptions.Data = [];

                    if (Show != undefined && Show != null) {
                        Show.ListShow = 'none';
                        Show.IconShow = 'block';
                    }

                    this._HelperService.NotifyError(_Response.Message);
                    return ListOptions;
                }
            },
            _Error => {

                if (Show != undefined && Show != null) {
                    Show.ListShow = 'none';
                    Show.IconShow = 'block';
                }

                this._HelperService.HandleException(_Error);
                return ListOptions;
            });
        return ListOptions;
    }

    List_GetDataTur(ListOptions: OList, Show?: any): OList {

        if (Show != undefined && Show != null) {
            Show.ListShow = 'none';
            Show.IconShow = 'block';
        }

        ListOptions.SearchCondition = '';
        ListOptions = this.List_GetSearchConditionTur(ListOptions);
        if (ListOptions.ActivePage == 1) {
            ListOptions.RefreshCount = true;
        }
        var SortExpression = ListOptions.Sort.SortDefaultColumn + ' ' + ListOptions.Sort.SortDefaultOrder;;

        if (ListOptions.Sort.SortDefaultName) {
            ListOptions.Sort.SortDefaultName = ListOptions.Sort.SortDefaultName.replace(' desc', '');
            ListOptions.Sort.SortDefaultName = ListOptions.Sort.SortDefaultName.replace(' asc', '');
            ListOptions.Sort.SortDefaultName = ListOptions.Sort.SortDefaultName + ' ' + ListOptions.Sort.SortDefaultOrder;
        }

        if (ListOptions.Sort.SortColumn != undefined && ListOptions.Sort.SortColumn != null && ListOptions.Sort.SortColumn != '') {
            if (ListOptions.Sort.SortOrder != undefined && ListOptions.Sort.SortOrder != null && ListOptions.Sort.SortOrder != '') {
                SortExpression = ListOptions.Sort.SortColumn + ' ' + ListOptions.Sort.SortOrder;
            }
            else {
                SortExpression = ListOptions.Sort.SortColumn + ' desc';
            }
        }

        var pData = {
            Task: ListOptions.Task,
            TotalRecords: ListOptions.TotalRecords,
            Offset: (ListOptions.ActivePage - 1) * ListOptions.PageRecordLimit,
            Limit: ListOptions.PageRecordLimit,
            RefreshCount: ListOptions.RefreshCount,
            SearchCondition: ListOptions.SearchCondition,
            SortExpression: SortExpression,
            Type: ListOptions.Type,
            ReferenceKey: ListOptions.ReferenceKey,
            StartDate: ListOptions.StartDate,
            EndDate: ListOptions.EndDate,
            ReferenceId: ListOptions.ReferenceId,
            SubReferenceId: ListOptions.SubReferenceId,
            SubReferenceKey: ListOptions.SubReferenceKey,
            ListType: ListOptions.ListType,
            IsDownload: false,
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(ListOptions.Location, pData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {

                    if (Show != undefined && Show != null) {
                        ListOptions = this.List_FormatResponse(ListOptions, _Response.Result, Show);
                    } else {
                        ListOptions = this.List_FormatResponse(ListOptions, _Response.Result);
                    }

                    return ListOptions;
                }
                else {
                    ListOptions.Data = [];

                    if (Show != undefined && Show != null) {
                        Show.ListShow = 'none';
                        Show.IconShow = 'block';
                    }

                    this._HelperService.NotifyError(_Response.Message);
                    return ListOptions;
                }
            },
            _Error => {

                if (Show != undefined && Show != null) {
                    Show.ListShow = 'none';
                    Show.IconShow = 'block';
                }

                this._HelperService.HandleException(_Error);
                return ListOptions;
            });
        return ListOptions;
    }


    List_GetDataTerm(ListOptions: OList, Show?: any): OList {

        if (Show != undefined && Show != null) {
            Show.ListShow = 'none';
            Show.IconShow = 'block';
        }

        ListOptions.SearchCondition = '';
        ListOptions = this.List_GetSearchConditionTerm(ListOptions);
        if (ListOptions.ActivePage == 1) {
            ListOptions.RefreshCount = true;
        }
        var SortExpression = ListOptions.Sort.SortDefaultColumn + ' ' + ListOptions.Sort.SortDefaultOrder;

        if (ListOptions.Sort.SortDefaultName) {
            ListOptions.Sort.SortDefaultName = ListOptions.Sort.SortDefaultName.replace(' desc', '');
            ListOptions.Sort.SortDefaultName = ListOptions.Sort.SortDefaultName.replace(' asc', '');
            ListOptions.Sort.SortDefaultName = ListOptions.Sort.SortDefaultName + ' ' + ListOptions.Sort.SortDefaultOrder;
        }

        if (ListOptions.Sort.SortColumn != undefined && ListOptions.Sort.SortColumn != null && ListOptions.Sort.SortColumn != '') {
            if (ListOptions.Sort.SortOrder != undefined && ListOptions.Sort.SortOrder != null && ListOptions.Sort.SortOrder != '') {
                SortExpression = ListOptions.Sort.SortColumn + ' ' + ListOptions.Sort.SortOrder;
            }
            else {
                SortExpression = ListOptions.Sort.SortColumn + ' desc';
            }
        }

        var pData = {
            Task: ListOptions.Task,
            TotalRecords: ListOptions.TotalRecords,
            Offset: (ListOptions.ActivePage - 1) * ListOptions.PageRecordLimit,
            Limit: ListOptions.PageRecordLimit,
            RefreshCount: ListOptions.RefreshCount,
            SearchCondition: ListOptions.SearchCondition,
            SortExpression: SortExpression,
            Type: ListOptions.Type,
            ReferenceKey: ListOptions.ReferenceKey,
            StartDate: ListOptions.StartDate,
            EndDate: ListOptions.EndDate,
            ReferenceId: ListOptions.ReferenceId,
            SubReferenceId: ListOptions.SubReferenceId,
            SubReferenceKey: ListOptions.SubReferenceKey,
            ListType: ListOptions.ListType,
            IsDownload: false,
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(ListOptions.Location, pData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {

                    if (Show != undefined && Show != null) {
                        ListOptions = this.List_FormatResponse(ListOptions, _Response.Result, Show);
                    } else {
                        ListOptions = this.List_FormatResponse(ListOptions, _Response.Result);
                    }

                    this._HelperService.SortTerminals(ListOptions.Data);

                    return ListOptions;
                }
                else {
                    ListOptions.Data = [];

                    if (Show != undefined && Show != null) {
                        Show.ListShow = 'none';
                        Show.IconShow = 'block';
                    }

                    this._HelperService.NotifyError(_Response.Message);
                    return ListOptions;
                }
            },
            _Error => {

                if (Show != undefined && Show != null) {
                    Show.ListShow = 'none';
                    Show.IconShow = 'block';
                }

                this._HelperService.HandleException(_Error);
                return ListOptions;
            });
        return ListOptions;
    }

    List_DownloadCsv(ListOptions: OList) {
        var pData = {
            Task: ListOptions.Task,
            TotalRecords: ListOptions.TotalRecords,
            Offset: 0,
            Limit: ListOptions.TotalRecords,
            RefreshCount: false,
            SearchCondition: ListOptions.SearchCondition,
            Type: ListOptions.Type,
            ReferenceKey: ListOptions.ReferenceKey,
            StartDate: ListOptions.StartDate,
            EndDate: ListOptions.EndDate,
            ReferenceId: ListOptions.ReferenceId,
            SubReferenceId: ListOptions.SubReferenceId,
            ListType: ListOptions.ListType,
            IsDownload: ListOptions.IsDownload,
        };
        let _OResponse: Observable<OResponse>;
        _OResponse = this._HelperService.PostData(ListOptions.Location, pData);
        _OResponse.subscribe(
            _Response => {
                if (_Response.Status == this._HelperService.StatusSuccess) {
                    if (ListOptions.IsDownload) {
                        this._HelperService.NotifySuccess('Your download will be available shortly in downloads section');
                    }
                    else {
                        var _ResponseData = _Response.Result as OListResponse;
                        var Result = _ResponseData.Data;

                        ListOptions = this.List_FormatResponse(ListOptions, _Response.Result);
                        var CsvData = [];
                        var CsvLabels = [];
                        var CsvSystemNames = [];
                        ListOptions.VisibleHeaders.forEach(element => {
                            if (element.Show == true) {
                                CsvLabels.push(element.DisplayName);
                                CsvSystemNames.push(element.SystemName);
                            }
                        });
                        for (var index = 0; index < Result.length; index++) {
                            var Item = Result[index];
                            var Content: any = {};
                            CsvSystemNames.forEach(element => {
                                var item = Item[element];
                                if (item != undefined && item != null) {
                                    Content[element] = Item[element];
                                }
                                else {
                                    Content[element] = "n/a";
                                }
                            });
                            CsvData.push(Content);
                        }
                        var CsvOptions = {
                            showLabels: true,
                            useBom: false,
                            showTitle: true,
                            title: ListOptions.Title,
                            headers: CsvLabels,
                        };
                        this._HelperService.DownloadCsv(CsvData, ListOptions.Title, CsvOptions);
                    }
                }
            },
            _Error => {
                this._HelperService.HandleException(_Error);
            });
    }
    public S2_Status_Option: Select2Options;
    public S2_Sort_Option: Select2Options;
    public S2_Status_Transport: any;
    S2_Status_List(Value) {
        var PlaceHolder = 'Sort by status';
        var _Select: OSelect =
        {
            Task: this._HelperService.AppConfig.Api.Core.GetCoreHelpersLite,
            Location: this._HelperService.AppConfig.NetworkLocation.V2.System,
            SearchCondition: '',
            SortCondition: ['Name asc'],
            ReferenceId: 0,
            ReferenceKey: null,
            Fields: [
                {
                    SystemName: 'ReferenceId',
                    Type: this._HelperService.AppConfig.DataType.Text,
                    Id: true,
                    Text: false,
                },
                {
                    SystemName: 'Name',
                    Type: this._HelperService.AppConfig.DataType.Text,
                    Id: false,
                    Text: true,
                },
                {
                    SystemName: 'StatusCode',
                    Type: this._HelperService.AppConfig.DataType.Text,
                    SearchCondition: '=',
                    SearchValue: this._HelperService.AppConfig.Status.Active,
                }

            ],
        }
        _Select.SearchCondition = this._HelperService.GetSearchConditionStrict('', 'ParentCode', this._HelperService.AppConfig.DataType.Text, Value, '=');
        this.S2_Status_Transport = this.S2_BuildList(_Select) as any;
        this.S2_Status_Option = {
            placeholder: PlaceHolder,
            ajax: this.S2_Status_Transport,
            multiple: false,
            allowClear: true,
        };
    }

    public S2_SavedFilters_Option: Select2Options;
    public S2_NewSavedFilters_Option: Select2Options;


}



