import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}

@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [TranslateModule]
})
export class TranslaterModule { }




// "Activity": "Activity",
      // "Dashboard": "Dashboard",
      // "LoginHistory": "Login History",
      // "LastLogin": "Last Login",
      // "AccountType": "Account Type",
      // "AccountType_ER_Required": "Select account type",
      // "AccountOperationType": "Account Operations",
      // "AccountOperationType_ER_Required": "Select account operation type",
      // "Owner": "Owner",
      // "Owner_ER_Required": "Select Owner",

      // "ReferralCode": "Referral Code",
      // "ReferralCode_ER_Required": "enter referral code",
      // "ReferralUrl": "Referral Url",
      // "ReferralUrl_ER_Required": "enter referral url",
      // "Description": "Description",
      // "Description_ER_Required": "enter description",
      // "ApplicationStatus": "Application Status",
      // "RegistrationSource": "Registration Source",
      // "And": "&",
      // "Registration": "Registration",
      // "Count": "Count",
      // "Value": "Value",
      // 
      //
      // 
      // 
      // 
      // "ContactNumber": "Contact Number",
      // "Gender": "Gender",
      // "DateOfBirth": "DOB",
      // "Address": "Address",
      // "Latitude": "Latitude",
      // "Longitude": "Longitude",
      // 
      // 
      // "SecondaryPassword": "Secondary Password",
      // "Role": "Role",
      // "AppVersion": "App Version",
      // "AppKey": "App",
      // "AccessPin": "Access Pin",
      // "Country": "Country",
      // "Region": "Region",
      // "RegionArea": "Region Area",
      // "City": "City",
      // "CreateDate": "Reg. On",
      // 
      // "Account": "Account",
      // "Details": "Details",
      // "AccountCode": "Account Code",
      // "UpdateProfile": "Update Profile",
      // "EmailVerificationStatus": "Email Verification Status",
      // "EmailVerificationDate": "Email Verification Date",
      // "NumberVerificationStatus": "Number Verification Status",
      // "NumberVerificationStatusDate": "Number Verification Date",
      // 
      // "Poster": "Poster",
      // "Male": "Male",
      // "Female": "Female",
      // "Profile": "Profile",
      // "Credentials": "Credentials",
      // 
      // "OldPassword": "Old Password",
      // "OldPassword_ER_Required": "enter old password",
      // "NewPassword": "New password",
      // "NewPassword_ER_Required": "enter new password",
      // "NewPassword_ER_Length": "Password must be at least 8 characters in length and include at least one uppercase, one lowercase, one number and one special character.",
      // "OldAccessPin": "Old Access Pin",
      // "NewAccessPin": "New Access Pin",

      // 
      // "NewAccessPin_ER_Length": "new access pin must be 4 digit number",
      // "OldAccessPin_ER_Length": "old access pin must be 4 digit number",
      // "OldAccessPin_ER_Required": "enter old access pin",
      // "NewAccessPin_ER_Required": "enter new access pin",

      // 
      // 
      // "AccessPin_ER_Required": "enter access pin",
      // 
      // 
      // "Latitude_ER_Required": "enter latitude",
      // "Longitude_ER_Required": "enter longitude",
      //
      //

      // 
      // 
      // 
      // "AccessPin_ER_Length": "access pin must be 4 digit number",
      // 
      // "LocateAddress": "Locate Address",
      // "LocationMap": "Location Map",
      // "TerminalId": "Terminal Id",
      // "Merchant": "Merchant",
      // "Provider": "Provider",
      // "Acquirer": "Acquirer",
      // "Store": "Store"
